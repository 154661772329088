@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// footer
// ================================================================
.footer {
  width: 100%;
  height: auto;
  background-color: $color-footer;
}

.footer__inner {
  width: 100%;
  max-width: 1026px;
  margin-inline: auto;
  padding-top: min(9.8%, 100px);
  padding-bottom: min(14.7%, 150px);
}

.footer__wrapper {
  width: 100%;
  max-width: 89.7%;
  margin-inline: auto;
}

.footer__inquiry p {
  font-size: min(3.8vw, 36px);
  line-height: 1.6;
  font-weight: 700;
  letter-spacing: min(0.5vw, 4.3px);
}

.footer__address {
  margin-top: 0.9%;
}

.footer__address p {
  font-size: min(2.4vw, 24px);
  line-height: 1;
  font-weight: 700;
}

.footer__address address {
  font-style: normal;
  font-size: min(3.8vw, 36px);
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: min(0.5vw, 4.3px);
}

.footer__contact {
  margin-top: 7.3%;
  margin-left: 0.9%;
}

.footer__tel {
  display: inline-flex;
  align-items: center;
  @include mq(pc) {
    pointer-events: none;
  }
}

.footer__tel figure {
  max-width: 57px;
  width: 100%;
  aspect-ratio: 57/45;
  @include mq(sp-s) {
    max-width: 30px;
  }
}

.footer__tel figure img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.footer__tel tel {
  font-size: min(3.8vw, 38px);
  color: $color-default-font;
  font-weight: 700;
  margin-left: 12px;
  letter-spacing: 0px;

  @include mq(pc) {
    letter-spacing: min(0.5vw, 4.3px);
  }
}

.footer__mail {
  display: inline-flex;
  align-items: center;
  margin-top: 4.7%;
}

.footer__mail:hover figure {
  opacity: 0.7;
}

.footer__mail figure {
  max-width: 57px;
  width: 100%;
  aspect-ratio: 59/45;
  transition: opacity 0.3s;
  @include mq(sp-s) {
    max-width: 30px;
  }
}

.footer__mail figure img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.footer__mail p {
  font-size: min(3.8vw, 38px);
  color: $color-default-font;
  font-weight: 700;
  margin-left: 12px;
  letter-spacing: 0px;

  @include mq(pc) {
    letter-spacing: min(0.15vw, 1.5px);
  }
}

.footer__banner {
  width: 100%;
  max-width: 89.7%;
  // max-width: 68.2%;
  margin-inline: auto;
  margin-top: 7.1%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.footer__banner-natori {
  display: block;
}

.footer__banner-sightseeing {
  display: block;
  // margin-top: 7.2%;
}
