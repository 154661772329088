@charset "UTF-8";
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
li,
dt,
dd,
p,
div,
span,
img,
a,
table,
tr,
th,
td,
small,
button,
time,
figure {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

li,
dd {
  list-style-type: none;
}

header,
footer,
nav,
section,
article,
aside,
figure,
figcaption {
  display: block;
}

img {
  border: none;
  vertical-align: bottom;
}

a {
  cursor: pointer;
  text-decoration: none;
}

input,
textarea {
  -webkit-appearance: none;
  border-radius: 0;
}

@font-face {
  font-family: "MyFont-regular";
  src: url("../font/KozGoPro-Regular.otf");
  font-display: swap;
}
html {
  font-size: 16px;
}

body {
  color: #333333;
  font-family: "MyFont-regular", sans-serif;
  line-height: 1.5;
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  overflow-x: hidden;
  font-display: swap;
  background-color: white;
}

body.noscroll {
  overflow: hidden;
}

body.bg-gray {
  background-color: #4d4d4d;
}

:where(img, svg, img[src$=".svg"]) {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  transition: opacity 0.3s;
}

@media screen and (hover: hover) and (min-width: 768px) {
  a:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
*:focus {
  outline: none;
}

strong {
  font-weight: 900;
}

b {
  font-weight: 700;
}

input,
textarea {
  border: none;
  width: 100%;
}

.inner {
  width: 100%;
  height: 100%;
  margin-inline: auto;
  padding-inline: 4.26vw;
}
@media screen and (min-width: 768px) {
  .inner {
    width: min(1026px, 86vw);
    padding-inline: 0;
  }
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 10;
  background-color: transparent;
}

.header__inner {
  width: 91.7%;
  height: 100px;
  padding-top: 20px;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .header__inner {
    padding-top: 40px;
    height: 190px;
  }
}
@media screen and (max-width: 567px) {
  .header__inner {
    height: 80px;
  }
}

.header__logo {
  display: block;
  aspect-ratio: 150/54;
  max-width: 150px;
  height: 54px;
  width: 100%;
  z-index: 20;
}
@media screen and (max-width: 567px) {
  .header__logo {
    max-width: 100px;
    height: 36px;
  }
}

.header__logo figure {
  width: 100%;
  height: auto;
}

.header__logo figure img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.header__drawer {
  width: 80px;
  height: 80px;
  border: 1px solid #333333;
  border-radius: 5px;
  margin-top: 0px;
  margin-right: 0px;
  background-color: white;
  position: relative;
  z-index: 20;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .header__drawer {
    width: 120px;
    height: 120px;
    margin-top: 30px;
    margin-right: 30px;
  }
}
@media screen and (max-width: 567px) {
  .header__drawer {
    width: 60px;
    height: 60px;
  }
}

.header__drawer-wrapper {
  width: 75%;
  height: auto;
  aspect-ratio: 90/80;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
@media screen and (max-width: 567px) {
  .header__drawer-wrapper {
    aspect-ratio: 90/90;
  }
}

.header__drawer-line {
  width: 100%;
  height: auto;
  aspect-ratio: 90/50;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  position: relative;
}

.header__drawer-line span {
  display: block;
  width: 100%;
  height: 5px;
  background-color: #333333;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 0;
  transition: all 0.5s;
}
@media screen and (max-width: 567px) {
  .header__drawer-line span {
    height: 3px;
  }
}

.header__drawer-line span:nth-child(2) {
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translateY(-50%) translateX(-50%);
}

.header__drawer-line span:nth-child(3) {
  top: 100%;
  -webkit-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  transform: translateY(-100%) translateX(-50%);
}

.header__drawer.is-active .header__drawer-line span:nth-child(1) {
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(25deg);
  -ms-transform: translate(-50%, -50%) rotate(25deg);
  transform: translateY(-50%) translateX(-50%) rotate(25deg);
}

.header__drawer.is-active .header__drawer-line span:nth-child(2) {
  opacity: 0;
}

.header__drawer.is-active .header__drawer-line span:nth-child(3) {
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-25deg);
  -ms-transform: translate(-50%, -50%) rotate(-25deg);
  transform: translateY(-50%) translateX(-50%) rotate(-25deg);
}

.header__overlay {
  position: fixed;
  z-index: 15;
  top: 0;
  right: 0;
  width: 100%;
  height: 150vh;
  overflow-y: auto;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
}

.header__overlay::before {
  content: "";
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  opacity: 1;
  background-color: white;
}

.header__overlay.is-active {
  opacity: 1;
  pointer-events: auto;
}

.drawer__menu {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-y: scroll;
}

.drawer__nav {
  position: absolute;
  z-index: 15;
  max-width: 550px;
  width: 100%;
  height: auto;
  top: 0;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-inline: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
@media screen and (max-width: 567px) {
  .drawer__nav {
    max-width: 350px;
    padding-top: 50px;
  }
}

.drawer__item {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px dashed #333333;
}

.drawer__item.drawer__item-category {
  padding-top: 30px;
  padding-bottom: 5px;
}

.drawer__item a,
.drawer__item p {
  display: block;
  color: #333333;
  font-weight: 400;
  font-size: 28px;
  line-height: 1.25;
  position: relative;
}
@media screen and (max-width: 567px) {
  .drawer__item a,
  .drawer__item p {
    font-size: 18px;
  }
}

.drawer__item p {
  font-size: 36px;
  font-weight: 700;
}
@media screen and (max-width: 567px) {
  .drawer__item p {
    font-size: 24px;
  }
}

.drawer__item a:hover {
  opacity: 1;
}

.drawer__item a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.4rem;
  width: 100%;
  height: 0.125rem;
  background: red;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  transform-origin: left top;
  transition: transform 0.5s ease-out;
}

.drawer__item a:hover::after {
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
}
@media screen and (min-width: 768px) {
  .drawer__item a:hover::after {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.info {
  margin-top: min(19.5%, 200px);
}

.info .producer__btn {
  margin-top: 13%;
  margin-bottom: 4.4%;
}

.footer {
  width: 100%;
  height: auto;
  background-color: #a3b8c4;
}

.footer__inner {
  width: 100%;
  max-width: 1026px;
  margin-inline: auto;
  padding-top: min(9.8%, 100px);
  padding-bottom: min(14.7%, 150px);
}

.footer__wrapper {
  width: 100%;
  max-width: 89.7%;
  margin-inline: auto;
}

.footer__inquiry p {
  font-size: min(3.8vw, 36px);
  line-height: 1.6;
  font-weight: 700;
  letter-spacing: min(0.5vw, 4.3px);
}

.footer__address {
  margin-top: 0.9%;
}

.footer__address p {
  font-size: min(2.4vw, 24px);
  line-height: 1;
  font-weight: 700;
}

.footer__address address {
  font-style: normal;
  font-size: min(3.8vw, 36px);
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: min(0.5vw, 4.3px);
}

.footer__contact {
  margin-top: 7.3%;
  margin-left: 0.9%;
}

.footer__tel {
  display: inline-flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .footer__tel {
    pointer-events: none;
  }
}

.footer__tel figure {
  max-width: 57px;
  width: 100%;
  aspect-ratio: 57/45;
}
@media screen and (max-width: 567px) {
  .footer__tel figure {
    max-width: 30px;
  }
}

.footer__tel figure img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.footer__tel tel {
  font-size: min(3.8vw, 38px);
  color: #333333;
  font-weight: 700;
  margin-left: 12px;
  letter-spacing: 0px;
}
@media screen and (min-width: 768px) {
  .footer__tel tel {
    letter-spacing: min(0.5vw, 4.3px);
  }
}

.footer__mail {
  display: inline-flex;
  align-items: center;
  margin-top: 4.7%;
}

.footer__mail:hover figure {
  opacity: 0.7;
}

.footer__mail figure {
  max-width: 57px;
  width: 100%;
  aspect-ratio: 59/45;
  transition: opacity 0.3s;
}
@media screen and (max-width: 567px) {
  .footer__mail figure {
    max-width: 30px;
  }
}

.footer__mail figure img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.footer__mail p {
  font-size: min(3.8vw, 38px);
  color: #333333;
  font-weight: 700;
  margin-left: 12px;
  letter-spacing: 0px;
}
@media screen and (min-width: 768px) {
  .footer__mail p {
    letter-spacing: min(0.15vw, 1.5px);
  }
}

.footer__banner {
  width: 100%;
  max-width: 89.7%;
  margin-inline: auto;
  margin-top: 7.1%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.footer__banner-natori {
  display: block;
}

.footer__banner-sightseeing {
  display: block;
}

.top-fv__inner {
  width: 100%;
  max-width: 1026px;
  margin-inline: auto;
}

.top-fv__title {
  width: 78%;
  height: auto;
  margin-inline: auto;
  aspect-ratio: 808/339;
  margin-top: 20.5%;
}

.top-fv__title img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.top-fv__text {
  width: 100%;
  height: auto;
  aspect-ratio: 1026/1198;
}

.top-fv__text img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.producer__link {
  position: relative;
  z-index: 1;
  display: block;
}

.producer__link:hover {
  opacity: 1;
}

.producer__link::before {
  content: "";
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: #efabb8;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

@media screen and (min-width: 768px) {
  .producer__link:hover::before {
    opacity: 0.3;
  }
}

.producer__content + .producer__content {
  margin-top: 7.4%;
}

.producer__inner {
  width: 100%;
  max-width: 1026px;
  margin-inline: auto;
}

.producer__title {
  width: 92%;
  height: auto;
  margin-inline: auto;
  aspect-ratio: 945/216;
  margin-bottom: -4.5%;
  position: relative;
  z-index: 1;
}

.producer__title img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.producer__line {
  width: 100%;
  height: auto;
  margin-inline: auto;
  aspect-ratio: 1025/64;
}

.producer__line img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.producer__background {
  background-color: #faecf2;
  width: 100%;
}

.producer__content:nth-child(2) .producer__background,
.umi .producer__background {
  background-color: #e5f2fa;
}

.producer__content:nth-child(3) .producer__background,
.tahata .producer__background {
  background-color: #e9f1e0;
}

.producer__content:nth-child(4) .producer__background,
.kurashi .producer__background {
  background-color: #f9e0ca;
}

.producer__wrapper {
  padding-top: 7.6%;
  padding-bottom: 8.6%;
  width: 89.7%;
  height: auto;
  margin-inline: auto;
}

.producer__item:last-child .producer__dots {
  display: none;
}

.producer__text {
  width: 100%;
  height: auto;
  margin-inline: auto;
  aspect-ratio: 915/446;
  overflow: hidden;
}

.producer__text img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: top;
}

.producer__btn {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 150px;
  font-size: 43px;
  font-weight: 700;
  color: white;
  text-align: center;
  line-height: 1;
  letter-spacing: 0px;
  background-color: transparent;
  margin-inline: auto;
  border-radius: 20px;
  transition: color 0.3s ease-out;
}
@media screen and (max-width: 767px) {
  .producer__btn {
    font-size: 32px;
    height: 100px;
  }
}
@media screen and (max-width: 567px) {
  .producer__btn {
    font-size: 20px;
    height: 60px;
  }
}

.producer__btn::before,
.producer__btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.producer__btn::before {
  z-index: -1;
  opacity: 1;
  background-color: #efabb8;
  transition: opacity 0.5s ease-out;
  box-sizing: border-box;
}

.producer__btn::after {
  z-index: -2;
  opacity: 1;
  background-color: white;
  border: 2px solid #efabb8;
  box-sizing: border-box;
}

.producer__btn:hover {
  opacity: 1;
}
@media screen and (min-width: 768px) {
  .producer__btn:hover {
    color: #efabb8;
  }
}

@media screen and (min-width: 768px) {
  .producer__btn:hover::before {
    opacity: 0;
  }
}

.producer__content:nth-child(2) .producer__btn::before,
.umi .producer__btn::before,
.umi .producer__link::before {
  background-color: #63b5d4;
}

@media screen and (min-width: 768px) {
  .producer__content:nth-child(2) .producer__btn:hover,
  .umi .producer__btn:hover {
    color: #63b5d4;
  }
}

.producer__content:nth-child(2) .producer__btn::after,
.umi .producer__btn::after {
  border: 2px solid #63b5d4;
}

.producer__content:nth-child(3) .producer__btn::before,
.tahata .producer__btn::before,
.tahata .producer__link::before {
  background-color: #8dc37a;
}

@media screen and (min-width: 768px) {
  .producer__content:nth-child(3) .producer__btn:hover,
  .tahata .producer__btn:hover {
    color: #8dc37a;
  }
}

.producer__content:nth-child(3) .producer__btn::after,
.tahata .producer__btn::after {
  border: 2px solid #8dc37a;
}

.producer__content:nth-child(4) .producer__btn::before,
.kurashi .producer__btn::before,
.kurashi .producer__link::before {
  background-color: #e97648;
}

@media screen and (min-width: 768px) {
  .producer__content:nth-child(4) .producer__btn:hover,
  .kurashi .producer__btn:hover {
    color: #e97648;
  }
}

.producer__content:nth-child(4) .producer__btn::after,
.kurashi .producer__btn::after {
  border: 2px solid #e97648;
}

.producer__person {
  position: relative;
  z-index: 2;
  width: 100%;
  height: auto;
  margin-inline: auto;
  aspect-ratio: 901/495;
}

.producer__person img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}

.producer__dots {
  width: 100%;
  height: auto;
  margin-inline: auto;
  aspect-ratio: 906/101;
}

.producer__dots img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.article {
  margin-top: 54px;
  font-family: "ＭＳ Ｐゴシック", sans-serif;
}

.article__inner {
  max-width: 1024px;
  width: 100%;
  margin-inline: auto;
}

.article__items {
  padding-bottom: 100px;
}

.article__item + .article__item {
  margin-top: 70px;
}

.article__item video {
  display: block;
  width: 100%;
  aspect-ratio: 1280/720;
  height: auto;
  margin-inline: auto;
  max-width: 1024px;
}

.article__text {
  max-width: 87.8%;
  width: 100%;
  margin-inline: auto;
  margin-top: 100px;
}
@media screen and (max-width: 767px) {
  .article__text {
    margin-top: 50px;
  }
}

.article__text h2 {
  font-size: 40px;
  line-height: 1.64;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .article__text h2 {
    font-size: 20px;
  }
}

.article__text h2 span {
  font-size: 32px;
  line-height: 1.66;
  margin-top: 50px;
  display: block;
}
@media screen and (max-width: 767px) {
  .article__text h2 span {
    font-size: 18px;
    margin-top: 30px;
  }
}

.article__text p {
  font-size: 36px;
  line-height: 1.775;
}
@media screen and (max-width: 767px) {
  .article__text p {
    font-size: 18px;
  }
}

.article__text p span {
  font-weight: 600;
}

.article__text h2 + p {
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  .article__text h2 + p {
    margin-top: 30px;
  }
}

@media screen and (min-width: 768px) {
  .utils-hidden-mobile {
    display: inline-block !important;
  }
}
@media screen and (max-width: 767px) {
  .utils-hidden-mobile {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .utils-block-hidden-mobile {
    display: block !important;
  }
}
@media screen and (max-width: 767px) {
  .utils-block-hidden-mobile {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .utils-hidden-pc {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .utils-hidden-pc {
    display: inline-block !important;
  }
}

@media screen and (min-width: 768px) {
  .utils-block-hidden-pc {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .utils-block-hidden-pc {
    display: block !important;
  }
}

.utils-hidden {
  display: none !important;
}

.utils-color-invert {
  filter: brightness(0) invert(1) !important;
}

.utils-visible {
  visibility: visible !important;
  opacity: 1 !important;
}