@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// fv
// ================================================================
.top-fv {
  // margin-top: 20.5%;
  // margin-top: 210px;
}

.top-fv__inner {
  width: 100%;
  max-width: 1026px;
  margin-inline: auto;
}

.top-fv__title {
  width: 78%;
  height: auto;
  margin-inline: auto;
  aspect-ratio: 808/339;
  margin-top: 20.5%;
  // margin-top: 210px;
}

.top-fv__title img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.top-fv__text {
  width: 100%;
  height: auto;
  aspect-ratio: 1026/1198;
}

.top-fv__text img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
