@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// article
// ================================================================
.article {
  margin-top: 54px;
  font-family: $font-family-gothic;
}
.article__inner {
  max-width: 1024px;
  width: 100%;
  margin-inline: auto;
}

.article__items {
  padding-bottom: 100px;
}

.article__item + .article__item {
  margin-top: 70px;
}

.article__item video {
  display: block;
  width: 100%;
  aspect-ratio: 1280/720;
  height: auto;
  margin-inline: auto;
  max-width: 1024px;
}

.article__text {
  max-width: 87.8%;
  width: 100%;
  margin-inline: auto;
  margin-top: 100px;

  @include mq(mobile) {
    margin-top: 50px;
  }
}

.article__text h2 {
  font-size: 40px;
  line-height: 1.64;
  font-weight: 600;
  @include mq(mobile) {
    font-size: 20px;
  }
}

.article__text h2 span {
  font-size: 32px;
  line-height: 1.66;
  margin-top: 50px;
  display: block;
  @include mq(mobile) {
    font-size: 18px;
    margin-top: 30px;
  }
}

.article__text p {
  font-size: 36px;
  line-height: 1.775;

  // @include mq(pc) {
  //   font-size: 40px;
  // }
  @include mq(mobile) {
    font-size: 18px;
  }
}

.article__text p span {
  font-weight: 600;
}

.article__text h2 + p {
  margin-top: 60px;

  @include mq(mobile) {
    margin-top: 30px;
  }
}
