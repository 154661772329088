@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// producer
// ================================================================
.producer__items {
}

.producer__link {
  position: relative;
  z-index: 1;
  display: block;
}
.producer__link:hover {
  opacity: 1;
}

.producer__link::before {
  content: "";
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: $color-pink-btn;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
.producer__link:hover::before {
  @include mq(pc) {
    opacity: 0.3;
  }
}

.producer__content + .producer__content {
  margin-top: 7.4%;
}

.producer__inner {
  width: 100%;
  max-width: 1026px;
  margin-inline: auto;
}

.producer__title {
  width: 92%;
  height: auto;
  margin-inline: auto;
  aspect-ratio: 945/216;
  margin-bottom: -4.5%;
  position: relative;
  z-index: 1;
}
.producer__title img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.producer__line {
  width: 100%;
  height: auto;
  margin-inline: auto;
  aspect-ratio: 1025/64;
}

.producer__line img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.producer__background {
  background-color: $color-pink;
  width: 100%;
}

.producer__content:nth-child(2) .producer__background,
.umi .producer__background {
  background-color: $color-blue;
}
.producer__content:nth-child(3) .producer__background,
.tahata .producer__background {
  background-color: $color-green;
}
.producer__content:nth-child(4) .producer__background,
.kurashi .producer__background {
  background-color: $color-skin;
}

.producer__wrapper {
  padding-top: 7.6%;
  padding-bottom: 8.6%;
  width: 89.7%;
  height: auto;
  margin-inline: auto;
  // aspect-ratio: 921/1849;
}

.producer__item:last-child .producer__dots {
  display: none;
}

.producer__text {
  width: 100%;
  height: auto;
  margin-inline: auto;
  aspect-ratio: 915/446;
  overflow: hidden;
}

.producer__text img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: top;
}

// ================================================================
// producer__btn
// ================================================================
.producer__btn {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 150px;
  @include txt(43px, 700, $color-white, center);
  @include txt-option(1, 0px);
  background-color: transparent;
  margin-inline: auto;
  border-radius: 20px;
  transition: color 0.3s ease-out;

  @include mq(mobile) {
    font-size: 32px;
    height: 100px;
  }
  @include mq(sp-s) {
    font-size: 20px;
    height: 60px;
  }
}
.producer__btn::before,
.producer__btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}
.producer__btn::before {
  z-index: -1;
  opacity: 1;
  background-color: $color-pink-btn;
  transition: opacity 0.5s ease-out;
  box-sizing: border-box;
}
.producer__btn::after {
  z-index: -2;
  opacity: 1;
  background-color: $color-white;
  border: 2px solid $color-pink-btn;
  box-sizing: border-box;
}

.producer__btn:hover {
  opacity: 1;
  @include mq(pc) {
    color: $color-pink-btn;
  }
}
.producer__btn:hover::before {
  @include mq(pc) {
    opacity: 0;
  }
}
.producer__content:nth-child(2) .producer__btn::before,
.umi .producer__btn::before,
.umi .producer__link::before {
  background-color: $color-blue-btn;
}
.producer__content:nth-child(2) .producer__btn:hover,
.umi .producer__btn:hover {
  @include mq(pc) {
    color: $color-blue-btn;
  }
}
.producer__content:nth-child(2) .producer__btn::after,
.umi .producer__btn::after {
  border: 2px solid $color-blue-btn;
}

.producer__content:nth-child(3) .producer__btn::before,
.tahata .producer__btn::before,
.tahata .producer__link::before {
  background-color: $color-green-btn;
}
.producer__content:nth-child(3) .producer__btn:hover,
.tahata .producer__btn:hover {
  @include mq(pc) {
    color: $color-green-btn;
  }
}
.producer__content:nth-child(3) .producer__btn::after,
.tahata .producer__btn::after {
  border: 2px solid $color-green-btn;
}

.producer__content:nth-child(4) .producer__btn::before,
.kurashi .producer__btn::before,
.kurashi .producer__link::before {
  background-color: $color-skin-btn;
}
.producer__content:nth-child(4) .producer__btn:hover,
.kurashi .producer__btn:hover {
  @include mq(pc) {
    color: $color-skin-btn;
  }
}
.producer__content:nth-child(4) .producer__btn::after,
.kurashi .producer__btn::after {
  border: 2px solid $color-skin-btn;
}
// .producer__content:nth-child(2) .producer__btn {
//   background-color: $color-blue-btn;
// }
// .producer__content:nth-child(3) .producer__btn {
//   background-color: $color-green-btn;
// }
// .producer__content:nth-child(4) .producer__btn {
//   background-color: $color-skin-btn;
// }

// ================================================================
// producer__person
// ================================================================
.producer__person {
  position: relative;
  z-index: 2;
  width: 100%;
  height: auto;
  margin-inline: auto;
  aspect-ratio: 901/495;
}

.producer__person img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}

.producer__dots {
  width: 100%;
  height: auto;
  margin-inline: auto;
  aspect-ratio: 906/101;
}

.producer__dots img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
