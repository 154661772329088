@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// template
// ================================================================
.info {
  margin-top: min(19.5%, 200px);
}

.info .producer__btn {
  margin-top: 13%;
  margin-bottom: 4.4%;
}
