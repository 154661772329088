@charset "UTF-8";

// ================================================================
// フォント変数
// ================================================================

@font-face {
  font-family: "MyFont-regular";
  src: url("../font/KozGoPro-Regular.otf");
  font-display: swap;
}

// @font-face {
//   font-family: "MyFont-bold";
//   src: url("../font/KozGoPro-Bold.otf");
// }

$font-family-base: "MyFont-regular", sans-serif;
// $font-family-bold: "MyFont-bold", sans-serif;
$font-family-gothic: "ＭＳ Ｐゴシック", sans-serif;
