@charset "UTF-8";
// ================================================================
// カラー変数
// ================================================================
$color-default-font: #333333;
$color-gray-font: #9b9b9b;

$color-white: white;
$color-pink: #faecf2;
$color-blue: #e5f2fa;
$color-green: #e9f1e0;
$color-skin: #f9e0ca;

$color-pink-btn: #efabb8;
$color-blue-btn: #63b5d4;
$color-green-btn: #8dc37a;
$color-skin-btn: #e97648;

$color-red: red;

$color-footer: #a3b8c4;
