@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// header
// ================================================================
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 10;
  background-color: transparent;
}

.header__inner {
  width: 91.7%;
  height: 100px;
  padding-top: 20px;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;

  @include mq(pc) {
    padding-top: 40px;
    height: 190px;
  }

  @include mq(sp-s) {
    height: 80px;
  }
}

.header__logo {
  display: block;
  aspect-ratio: 150/54;
  max-width: 150px;
  height: 54px;
  width: 100%;
  z-index: 20;

  @include mq(sp-s) {
    max-width: 100px;
    height: 36px;
  }
}

.header__logo figure {
  width: 100%;
  height: auto;
}

.header__logo figure img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

// ================================================================
// header__drawer
// ================================================================
.header__drawer {
  width: 80px;
  height: 80px;
  border: 1px solid $color-default-font;
  border-radius: 5px;
  margin-top: 0px;
  margin-right: 0px;
  background-color: $color-white;
  position: relative;
  z-index: 20;
  cursor: pointer;

  @include mq(pc) {
    width: 120px;
    height: 120px;
    margin-top: 30px;
    margin-right: 30px;
  }
  @include mq(sp-s) {
    width: 60px;
    height: 60px;
  }
}

.header__drawer-wrapper {
  // max-width: 60px;
  width: 75%;
  height: auto;
  aspect-ratio: 90/80;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  display: flex;
  flex-flow: column;
  justify-content: space-between;

  // @include mq(pc) {
  //   max-width: 90px;
  // }

  @include mq(sp-s) {
    aspect-ratio: 90/90;
  }
}

.header__drawer-line {
  width: 100%;
  height: auto;
  aspect-ratio: 90/50;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  position: relative;
}

.header__drawer-line span {
  display: block;
  width: 100%;
  height: 5px;
  background-color: $color-default-font;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 0;
  transition: all 0.5s;

  @include mq(sp-s) {
    height: 3px;
  }
}

.header__drawer-line span:nth-child(2) {
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translateY(-50%) translateX(-50%);
}

.header__drawer-line span:nth-child(3) {
  top: 100%;
  -webkit-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  transform: translateY(-100%) translateX(-50%);
}

.header__drawer.is-active .header__drawer-line span:nth-child(1) {
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(25deg);
  -ms-transform: translate(-50%, -50%) rotate(25deg);
  transform: translateY(-50%) translateX(-50%) rotate(25deg);
}
.header__drawer.is-active .header__drawer-line span:nth-child(2) {
  opacity: 0;
}

.header__drawer.is-active .header__drawer-line span:nth-child(3) {
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-25deg);
  -ms-transform: translate(-50%, -50%) rotate(-25deg);
  transform: translateY(-50%) translateX(-50%) rotate(-25deg);
}

// ================================================================
// header__overlay
// ================================================================
.header__overlay {
  position: fixed;
  z-index: 15;
  top: 0;
  right: 0;
  width: 100%;
  height: 150vh;
  overflow-y: auto;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
}
.header__overlay::before {
  content: "";
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  opacity: 1;
  background-color: $color-white;
}
.header__overlay.is-active {
  opacity: 1;
  pointer-events: auto;
}
.drawer__menu {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-y: scroll;
}
.drawer__nav {
  position: absolute;
  z-index: 15;
  max-width: 550px;
  width: 100%;
  height: auto;
  top: 0;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-inline: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  @include mq(sp-s) {
    max-width: 350px;
    padding-top: 50px;
  }
}

.drawer__item {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px dashed $color-default-font;
}

.drawer__item.drawer__item-category {
  padding-top: 30px;
  padding-bottom: 5px;
}

.drawer__item a,
.drawer__item p {
  display: block;
  // width: fit-content;
  color: $color-default-font;
  font-weight: 400;
  font-size: 28px;
  line-height: 1.25;
  position: relative;

  @include mq(sp-s) {
    font-size: 18px;
  }
}

.drawer__item p {
  font-size: 36px;
  font-weight: 700;

  @include mq(sp-s) {
    font-size: 24px;
  }
}

.drawer__item a:hover {
  opacity: 1;
}

.drawer__item a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.4rem;
  width: 100%;
  height: 0.125rem;
  background: $color-red;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  transform-origin: left top;
  transition: transform 0.5s ease-out;
}

.drawer__item a:hover::after {
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);

  @include mq(pc) {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.drawer__item + .drawer__item {
}
