@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// inner
// ================================================================
.inner {
  width: 100%;
  height: 100%;
  margin-inline: auto;
  padding-inline: 4.26vw;
  @include mq(pc) {
    width: min(1026px, 86vw);
    padding-inline: 0;
  }
}
